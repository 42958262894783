import {
    Details,
    DetailsLayout
} from "src/app/components/shared/new-details-page/details-section/details-section.component";
import {
    WidgetHeader,
    WidgetHeaderLayout
} from "src/app/components/shared/new-details-page/widget-section/widget-section-header/widget-section-header.component";
import {
    Widget,
    WidgetLayout
} from "src/app/components/shared/new-details-page/widget-section/widget-section.component";
import { SharedService } from "src/app/services/shared.service";
import _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { UsersService } from "../pages/account-management/users/users.service";
import { ElementRef } from "@angular/core";

export abstract class BaseLayout {
    abstract keys: {
        primaryDetails: readonly string[];
        secondaryDetails: readonly string[];
        nonDeletedSecondaryDetails?: readonly string[];
        widgets: readonly string[];
    };
    abstract isMultiSelect: boolean;
    abstract widgets: Widget[];
    abstract widgetHeaders: WidgetHeader[];
    abstract primaryDetails: Details[];
    abstract secondaryDetails: Details[];
    protected abstract primaryDetailsDefaultLayout: DetailsLayout[];
    protected abstract secondaryDetailsDefaultLayout: DetailsLayout[];
    protected abstract widgetHeadersDefaultLayout: WidgetHeaderLayout[];
    protected abstract widgetsDefaultLayout: { [key: string]: WidgetLayout };
    abstract initIsMultiSelect: boolean;

    abstract primaryDetailsArea: ElementRef;
    abstract secondaryDetailsArea: ElementRef;

    protected abstract scopeName: string;
    abstract widgetsToRemoveOrAdd: { title: string; toHide: boolean }[];

    protected abstract translatedNames: Readonly<Record<string, string>>;

    isWidgetHeaderPinned = true;
    initIsWidgetHeaderPinned;
    isPrimaryDetailsSectionPinned = true;
    initIsPrimaryDetailsSectionPinned;
    isSecondaryDetailsSectionPinned = false;
    initIsSecondaryDetailsSectionPinned;
    isSecondaryDetailsSectionHidden = false;
    initIsSecondaryDetailsSectionHidden;
    isLocked = true;
    isMobile = false;

    layoutChanged = false;
    savingLayout = false;
    primaryDetailsAreaHeight = 0;
    secondaryDetailsAreaHeight = 0;

    initPrimaryDetails: Details[] = [];
    initSecondaryDetails: Details[] = [];
    initWidgetHeaders: WidgetHeader[] = [];
    multiWidgetHeaders: WidgetHeader[] = [];
    initWidgets: Widget[] = [];
    multiWidgets: Widget[] = [];

    constructor(
        protected userService: UsersService,
        protected sharedService: SharedService,
        protected translate: TranslateService
    ) {}

    protected getUpdatesDetailsContent(details: Details[]) {
        return details.map(detail => ({
            ...detail,
            data: detail.data.map(detailData => ({
                ...detailData,
                ...this.updateDetailContent(detailData.title)
            }))
        }));
    }

    protected abstract updateDetailContent(title: string);

    protected abstract addOrRemoveFromDetails();

    checkForUnsavedChanges() {
        const widgetsEqual = _.isEqual(
            this.initWidgets.map(({ title, isSelected, index, widthLevel, isFullyMaximized, x, y, w, h }) => ({
                title,
                isSelected,
                index,
                widthLevel,
                isFullyMaximized,
                x,
                y,
                w,
                h
            })),
            this.multiWidgets.map(({ title, isSelected, index, widthLevel, isFullyMaximized, x, y, w, h }) => ({
                title,
                isSelected,
                index,
                widthLevel,
                isFullyMaximized,
                x,
                y,
                w,
                h
            }))
        );

        const widgetHeadersEqual = _.isEqual(
            this.initWidgetHeaders.map(({ title, isSelected }) => ({ title, isSelected })),
            this.multiWidgetHeaders.map(({ title, isSelected }) => ({ title, isSelected }))
        );
        const pinnedPrimaryEqual = this.initIsPrimaryDetailsSectionPinned == this.isPrimaryDetailsSectionPinned;
        const pinnedSecondaryEqual = this.initIsSecondaryDetailsSectionPinned == this.isSecondaryDetailsSectionPinned;
        const pinnedWidgetHeaderEqual = this.initIsWidgetHeaderPinned == this.isWidgetHeaderPinned;
        const hiddenSecondaryInfoEqual =
            this.initIsSecondaryDetailsSectionHidden == this.isSecondaryDetailsSectionHidden;
        const primaryDetailsEqual = _.isEqual(
            this.initPrimaryDetails.map(({ title, index }) => ({ title, index })),
            this.primaryDetails.map(({ title, index }) => ({ title, index }))
        );
        const secondaryDetailsEqual = _.isEqual(
            this.initSecondaryDetails.map(({ title, index, isDeleted }) => ({ title, index, isDeleted })),
            this.secondaryDetails.map(({ title, index, isDeleted }) => ({ title, index, isDeleted }))
        );
        const multiSelectEqual = this.initIsMultiSelect == this.isMultiSelect;

        if (
            widgetsEqual &&
            widgetHeadersEqual &&
            pinnedPrimaryEqual &&
            pinnedSecondaryEqual &&
            pinnedWidgetHeaderEqual &&
            hiddenSecondaryInfoEqual &&
            primaryDetailsEqual &&
            secondaryDetailsEqual &&
            multiSelectEqual
        )
            this.layoutChanged = false;
        else this.layoutChanged = true;
    }

    protected async saveLayout(reset?: boolean) {
        if (!reset) this.savingLayout = true;

        let widgetLayout: WidgetLayout[];
        let widgetHeaderLayout: WidgetHeaderLayout[];
        if (this.isMultiSelect) {
            widgetLayout = this.multiWidgets.map(
                ({ title, isHidden, index, isSelected, widthLevel, isFullyMaximized, x, y, w, h, key }) => ({
                    title,
                    isHidden,
                    index,
                    isSelected,
                    widthLevel,
                    isFullyMaximized,
                    x,
                    y,
                    w,
                    h,
                    key
                })
            );
            widgetHeaderLayout = this.widgetHeaders.map(({ title, isHidden, index, isSelected, key }) => ({
                title,
                isHidden,
                index,
                isSelected,
                key
            }));
        } else {
            widgetLayout = this.widgets.map(
                ({ title, isHidden, index, isSelected, widthLevel, isFullyMaximized, x, y, w, h, key }) => ({
                    title,
                    isHidden,
                    index,
                    isSelected,
                    widthLevel,
                    isFullyMaximized,
                    x,
                    y,
                    w,
                    h,
                    key
                })
            );
            widgetHeaderLayout = this.multiWidgetHeaders.map(({ title, isHidden, index, isSelected, key }) => ({
                title,
                isHidden,
                index,
                isSelected,
                key
            }));
        }
        const primaryDetailsLayout: DetailsLayout[] = this.primaryDetails.map(
            ({ title, isHidden, index, isDeleted, key }) => ({
                title,
                isHidden,
                index,
                isDeleted,
                key
            })
        );
        const secondaryDetailsLayout: DetailsLayout[] = this.secondaryDetails.map(
            ({ title, isHidden, index, isDeleted, key }) => ({
                title,
                isHidden,
                index,
                isDeleted,
                key
            })
        );

        const pinnedSectionLayout = {
            isWidgetHeaderPinned: this.isWidgetHeaderPinned,
            isPrimaryDetailsSectionPinned: this.isPrimaryDetailsSectionPinned,
            isSecondaryDetailsSectionPinned: this.isSecondaryDetailsSectionPinned,
            isSecondaryDetailsSectionHidden: this.isSecondaryDetailsSectionHidden
        };

        const layout = {
            widgetLayout,
            widgetHeaderLayout,
            primaryDetailsLayout,
            secondaryDetailsLayout,
            pinnedSectionLayout,
            isMultiSelect: this.isMultiSelect,
            selectedWidget: this.isMultiSelect
                ? null
                : this.widgetHeaders.filter(head => head.isSelected === true)[0]?.title
        };

        if (!reset) await this.userService.updateLayout(this.scopeName, layout);
        else await this.userService.updateLayout(this.scopeName, {});

        this.layoutChanged = false;
        this.savingLayout = false;
    }

    protected async resetLayout() {
        this.primaryDetails = this.sharedService.getWithLayout(this.primaryDetails, this.primaryDetailsDefaultLayout);
        this.secondaryDetails = this.sharedService.getWithLayout(
            this.secondaryDetails,
            this.secondaryDetailsDefaultLayout
        );
        this.widgetHeaders = this.sharedService.getWithLayout(this.widgetHeaders, this.widgetHeadersDefaultLayout);
        this.widgets = this.sharedService.getWithLayout(this.widgets, Object.values(this.widgetsDefaultLayout));
        this.isMultiSelect = false;
        this.isPrimaryDetailsSectionPinned = true;
        this.isSecondaryDetailsSectionPinned = false;
        this.isWidgetHeaderPinned = true;
        this.isSecondaryDetailsSectionHidden = false;
        await this.saveLayout(true);
        this.addOrRemoveFromDetails();
        this.addOrRemoveFromWidgets();
        // Set Init Values for comparison
        this.initIsMultiSelect = false;
        this.initIsPrimaryDetailsSectionPinned = true;
        this.initIsSecondaryDetailsSectionPinned = false;
        this.initIsSecondaryDetailsSectionHidden = false;
        this.initIsWidgetHeaderPinned = true;
        this.initPrimaryDetails = [...this.primaryDetails];
        this.initSecondaryDetails = [...this.secondaryDetails];
        this.initWidgetHeaders = [...this.widgetHeaders];
        this.multiWidgetHeaders = [...this.widgetHeaders];
        this.initWidgets = [...this.widgets];
        this.multiWidgets = [...this.widgets];
        // Select first widget by default in single select mode
        if (!this.isMultiSelect) {
            this.widgetHeaders = this.widgetHeaders.map((widget, i) => ({ ...widget, isSelected: i == 0 }));
            this.widgets = this.widgets.map((widget, i) => ({ ...widget, isSelected: i == 0 }));
        }
        this.layoutChanged = false;
    }

    protected async revertLayoutChanges() {
        const layout = await this.userService.getLayout(this.scopeName);

        if (this.sharedService.isEmptyObject(layout)) {
            await this.resetLayout();
            return;
        }

        if (_.isBoolean(layout.isMultiSelect)) this.isMultiSelect = layout.isMultiSelect;
        this.primaryDetails = this.sharedService.getWithLayout(this.primaryDetails, layout?.primaryDetailsLayout);
        this.secondaryDetails = this.sharedService.getWithLayout(this.secondaryDetails, layout?.secondaryDetailsLayout);

        // Check if saved layout has old widget definitions
        const oldlayout = layout.widgetLayout.find(w => w.widthLevel);
        if (oldlayout === undefined) {
            this.widgets = this.sharedService.getWithLayout(this.widgets, layout?.widgetLayout);
            this.widgetHeaders = this.sharedService.getWithLayout(this.widgetHeaders, layout?.widgetHeaderLayout);
        } else {
            this.widgetHeaders = this.sharedService.getWithLayout(this.widgetHeaders, this.widgetHeadersDefaultLayout);
            this.widgets = this.sharedService.getWithLayout(this.widgets, Object.values(this.widgetsDefaultLayout));
        }

        this.isPrimaryDetailsSectionPinned = layout?.pinnedSectionLayout?.isPrimaryDetailsSectionPinned ?? true;
        this.isSecondaryDetailsSectionPinned = layout?.pinnedSectionLayout?.isSecondaryDetailsSectionPinned ?? false;
        this.isWidgetHeaderPinned = layout?.pinnedSectionLayout?.isWidgetHeaderPinned ?? true;
        this.isSecondaryDetailsSectionHidden = layout?.pinnedSectionLayout.isSecondaryDetailsSectionHidden ?? false;
        //
        if (this.isMobile) {
            this.isMultiSelect = false;
            this.isWidgetHeaderPinned = true;
        }
        if (!this.isMultiSelect) {
            // TODO: change to first widget instead of details
            if (!layout.selectedWidget) layout.selectedWidget = this.translate.instant("DETAILS");
            this.widgetHeaders = this.widgetHeaders.map(widget =>
                widget.title === layout.selectedWidget
                    ? { ...widget, isSelected: true }
                    : { ...widget, isSelected: false }
            );
            this.widgets = this.widgets.map(widget =>
                widget.title === layout.selectedWidget
                    ? { ...widget, isSelected: true }
                    : { ...widget, isSelected: false }
            );
        }
        //
        this.addOrRemoveFromDetails();
        this.addOrRemoveFromWidgets();

        // Set Init Values for comparison
        this.initIsMultiSelect = this.isMultiSelect ? true : false;
        this.initIsPrimaryDetailsSectionPinned = layout?.pinnedSectionLayout?.isPrimaryDetailsSectionPinned ?? true;
        this.initIsSecondaryDetailsSectionPinned =
            layout?.pinnedSectionLayout?.isSecondaryDetailsSectionPinned ?? false;
        this.initIsWidgetHeaderPinned = layout?.pinnedSectionLayout?.isWidgetHeaderPinned ?? true;
        this.initIsSecondaryDetailsSectionHidden =
            layout?.pinnedSectionLayout?.isSecondaryDetailsSectionHidden ?? false;
        this.initPrimaryDetails = [...this.primaryDetails];
        this.initSecondaryDetails = [...this.secondaryDetails];
        if (!this.isMultiSelect) {
            if (oldlayout === undefined) {
                this.initWidgetHeaders = this.sharedService.getWithLayout(
                    this.widgetHeaders,
                    layout?.widgetHeaderLayout
                );
                this.initWidgets = this.sharedService.getWithLayout(this.widgets, layout?.widgetLayout);
                this.multiWidgetHeaders = this.sharedService.getWithLayout(
                    this.widgetHeaders,
                    layout?.widgetHeaderLayout
                );
                this.multiWidgets = this.sharedService.getWithLayout(this.widgets, layout?.widgetLayout);
            } else {
                this.initWidgetHeaders = this.sharedService.getWithLayout(
                    this.widgetHeaders,
                    this.widgetHeadersDefaultLayout
                );
                this.initWidgets = this.sharedService.getWithLayout(
                    this.widgets,
                    Object.values(this.widgetsDefaultLayout)
                );
                this.multiWidgetHeaders = this.sharedService.getWithLayout(
                    this.widgetHeaders,
                    this.widgetHeadersDefaultLayout
                );
                this.multiWidgets = this.sharedService.getWithLayout(
                    this.widgets,
                    Object.values(this.widgetsDefaultLayout)
                );
            }
        } else {
            this.initWidgetHeaders = [...this.widgetHeaders];
            this.initWidgets = [...this.widgets];
            this.multiWidgetHeaders = [...this.widgetHeaders];
            this.multiWidgets = [...this.widgets];
        }
        this.layoutChanged = false;
    }

    protected addOrRemoveFromWidgets() {
        for (const { title, toHide } of this.widgetsToRemoveOrAdd) {
            const widgetIndex = this.widgets.findIndex(widget => title === widget.title);
            const widgetHeaderIndex = this.widgetHeaders.findIndex(widget => title === widget.title);
            if (this.widgets[widgetIndex].isHidden !== toHide) {
                this.widgets[widgetIndex].isHidden = toHide;
                this.widgetHeaders[widgetHeaderIndex].isHidden = toHide;
            }
            if (toHide) {
                this.widgets[widgetIndex].isSelected = false;
                this.widgetHeaders[widgetHeaderIndex].isSelected = false;
            }
        }
        const noWidgetsSelected = this.widgets.every(widget => !widget.isSelected);
        if (noWidgetsSelected) {
            // select first widget by default
            this.widgetHeaders[0].isSelected = true;
            this.widgets[0].isSelected = true;
        }
        // Trigger ngOnChanges on childComponent
        this.widgets = _.cloneDeep(this.widgets);
        this.widgetHeaders = _.cloneDeep(this.widgetHeaders);
    }

    onWidgetSelectChange(widgetHeaders: WidgetHeader[]) {
        for (const { title, isSelected } of widgetHeaders) {
            const widgetIndex = this.widgets.findIndex(w => w.title === title);
            this.widgets[widgetIndex] = { ...this.widgets[widgetIndex], isSelected };
        }
        // Trigeers ngOnChange
        this.widgetHeaders = [...widgetHeaders];
        this.widgets = [...this.widgets];
        if (this.isMultiSelect) this.multiWidgets = [...this.widgets];
        this.checkForUnsavedChanges();
    }

    lockChanged(bool: boolean) {
        this.isLocked = bool;
        localStorage.setItem("isInterfaceLocked", this.isLocked.toString());
    }

    getDetailsAreaHeights() {
        this.primaryDetailsAreaHeight = this.primaryDetailsArea?.nativeElement?.offsetHeight;
        this.secondaryDetailsAreaHeight = this.secondaryDetailsArea?.nativeElement?.offsetHeight;
    }

    multiSelectChanged(bool: boolean) {
        this.isMultiSelect = bool;
        localStorage.setItem("isClusterMultiselect", this.isMultiSelect.toString());
    }

    onWidgetsLayoutChange(widgets: Widget[]) {
        if (!this.isMultiSelect) return;
        this.multiWidgets = [...widgets];

        this.checkForUnsavedChanges();
    }

    protected translateList<K extends string>(names: K[], translate: TranslateService): Record<K, string> {
        return names.reduce((obj, name) => {
            obj[name] = translate.instant(name);
            return obj;
        }, {} as Record<K, string>);
    }
}
